import { useState, useEffect } from "react";
import ApiServices from "../../utils/services";

const SetWinner = () => {
  const [numbers, setNumbers] = useState({
    day: 0,
    hour: 0,
  });
  const [cityName, setCityName] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const fetchNumbers = async () => {
    const [winnerNumbers, cityNames] = await Promise.all([
      ApiServices.getWinnerNumbers(),
      ApiServices.getCityName(),
    ]);
    setNumbers(winnerNumbers.data);
    setCityName(cityNames.data);
  };

  const fetchHourWinner = async (city) => {
    if (!city) return;
    const response = await ApiServices.getHourWinner(city); // Adjust according to your API service
    if (response.status === 200) {
      console.log(response.hourWinner)
      setNumbers((prevNumbers) => ({ ...prevNumbers, hour: response?.hourWinner }));
    }
  };

  useEffect(() => {
    fetchNumbers();
  }, []);

  const updateDayWinner = async () => {
    const data = await ApiServices.winnerNumbersUpdate({
      day: numbers?.day,
    });
    alert(data.status === 200 ? 'Day winner updated' : 'Internal server error !');
  };

  const updateHourWinner = async () => {
    if (!selectedCity) {
      alert('Please select a city.');
      return;
    }
    const data = await ApiServices.HourwinnerNumbersUpdate({
      hour: numbers?.hour,
      city: selectedCity,
    });
    alert(data.status === 200 ? 'Hour winner updated' : 'Internal server error !');
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    fetchHourWinner(city);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Set Winner Count</h6>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="text-primary">Today's Winner Count</h6>
                </div>
                <div className="col-md-3">
                  <input
                    type="number"
                    className="form-control"
                    value={numbers?.day}
                    name="day"
                    onChange={(e) =>
                      setNumbers({
                        ...numbers,
                        day: e.target.value,
                      })
                    }
                  />
                  <button className="btn btn-primary mt-2" onClick={updateDayWinner}>
                    Update Global Winner Count
                  </button>
                </div>

                <div className="col-md-12 mt-3">
                  <h6 className="text-primary">Hour Winner Count</h6>
                </div>
                <div className="col-md-3">
                  <input
                    type="number"
                    className="form-control"
                    value={numbers?.hour}
                    name="hour"
                    onChange={(e) =>
                      setNumbers({
                        ...numbers,
                        hour: e.target.value,
                      })
                    }
                  />
                  <select
                    className="form-control mt-2"
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    <option value="">Select City</option>
                    {cityName.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  <button className="btn btn-primary mt-2" onClick={updateHourWinner}>
                    Update Local Winner Count
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetWinner;
