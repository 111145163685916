import axios from "axios";
import { config } from "../../utils/config";
import ApiServices from "../../utils/services";
import { useState, useEffect } from "react";

const DashBoard = () => {
  const [cityName, setCityName] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const fetchCityNames = async () => {
      try {
        const response = await ApiServices.getCityName();
        if (response.data) {
          setCityName(response.data); // Assuming response.data contains the array of city names
        }
      } catch (error) {
        console.error("Failed to fetch city names:", error);
      }
    };

    fetchCityNames();
  }, []);

  const updateWinner = async () => {
    try {
      const response = await axios.get(`${config.apiEndPoint}/admin/daywinner`);
      console.log(response);
      if (response.status === 400) {
        alert("Please update the winner count!");
      } else {
        alert("Day Winner updated!");
      }
    } catch (error) {
      console.error("Error updating day winner:", error);
    }
  };

  const updateWinnerHour = async () => {
    // Check if a city has been selected
    if (!selectedCity) {
      alert('Please select a city first!');
      return;
    }
  
    try {
      const url = `${config.apiEndPoint}/admin/hourwinner?city=${encodeURIComponent(selectedCity)}`;
      const response = await axios.get(url);
      console.log(response);
      
      // Assuming the backend sends appropriate HTTP status codes
      if (response.data.status === 200) {
        alert("Hour Winner updated!");
      } else {
        // Handle other statuses as needed, might need to adjust based on your API
        alert("Please update the Hour winner count!");
      }
    } catch (error) {
      console.error("Error updating hour winner:", error);
      // Consider more specific error handling here based on error.response or error.message
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-primary" onClick={updateWinner}>
            Update Global Winner
          </button>
<br/>
<br/>
    
          <select
            className="form-control mt-2"
            value={selectedCity}
            onChange={handleCityChange}
          >
            <option value="">Select City</option>
            {cityName.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
          <button className="btn btn-primary mt-2" onClick={updateWinnerHour}>
            Update Local Winner
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
