import React, { useState, useEffect, useCallback,useMemo } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import ApiServices from '../../utils/services'; // Ensure ApiServices has the relevant calls
import './sidebar.css';

const Agents = () => {
  const [agentList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterInput, setFilterInput] = useState("");

  // Refactor fetching logic into a function
  const fetchAgents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiServices.agentlist(); // Make sure this is correctly named
      if (response.status === 200) {
        setAgentList(response.data);
      } else {
        setError('Failed to fetch agents');
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const columns = useMemo(() => [
    { Header: 'Representative ID', accessor: 'username' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Phone Number', accessor: 'number' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'State', accessor: 'state' },
    { Header: 'City', accessor: 'city' },
    { Header: 'Status', accessor: 'status', 
      Cell: ({ row }) => (
        <select 
          value={row.values.status} 
          onChange={e => handleStatusChange(e, row.original)}
          style={{ width: '100%' }}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      )
    },
    { Header: 'Created At', accessor: 'createdAt', Cell: ({ value }) => new Date(value).toLocaleDateString() }
  ], []);

  const handleStatusChange = async (event, agent) => {
    const newStatus = event.target.value;
    const data = {
        id: agent._id,
        status: newStatus
    };
    const response = await ApiServices.updateAgentStatus(data); // Implement this API method
    if (response.status === 200) {
      // Refetch the agent list to reflect the status update
      fetchAgents();
    } else {
      alert('Failed to update status');
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { pageIndex }
  } = useTable(
    { columns, data: agentList, initialState: { pageSize: 8 } },
    useGlobalFilter,
    usePagination
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Agent List</h6>
              <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder="Search agents..."
                className="form-control"
              />
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination" style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  marginBottom: '20px'
}}>
  <button
    onClick={() => previousPage()}
    disabled={!canPreviousPage}
    style={{
      cursor: 'pointer',
      padding: '10px 20px',
      marginRight: '10px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      fontWeight: 'bold',
      opacity: !canPreviousPage ? 0.5 : 1
    }}
  >
    Previous
  </button>
  <button
    onClick={() => nextPage()}
    disabled={!canNextPage}
    style={{
      cursor: 'pointer',
      padding: '10px 20px',
      marginLeft: '10px',
      backgroundColor: '#28a745',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      fontWeight: 'bold',
      opacity: !canNextPage ? 0.5 : 1
    }}
  >
    Next
  </button>
  <span style={{
    marginLeft: '20px',
    fontSize: '16px',
    color: '#343a40'
  }}>
    Page{' '}
    <strong>
      {pageIndex + 1} of {pageOptions.length}
    </strong>
  </span>
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agents;
