import { useEffect, useState } from "react";
import ApiServices from "../../utils/services";
import { Link } from "react-router-dom";
import WebHeader from "../../components/webTheme/header";
import SocialIcons from "../../components/ui/socialIcons";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
var $ = require('jquery');


const Winners = () => {
  const [data, setData] = useState([]);
  const [currentPlayed, setCurrentPlayed] = useState({});
  const [winningnumber, setwinningnumber] = useState([]);
  const fetchData = async () => {
    const data = await ApiServices.winningNumberList();
   setwinningnumber(data.data.winningnumber)
    setData(data.data);
    setTimeout(() => {
      $('#dataTable').DataTable();
  }, 1000)
  };
  const _setCurrentPlayed = (data) => {
    let _winNum = [];
    data.numbers.map((item) => {
      item.numbers.map((_item) => {
        _winNum = [..._winNum, _item]
      });
    });
    setCurrentPlayed({
      numbers: _winNum,
      totalplayed: data.totalplayed,
      winningnumber:data.winningnumber
    })
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <WebHeader />
      <section id="" class="d-flex align-items-center">
        <div class="container mt-5 innerpageContent">
          <div class="row">
          <table
                  class="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
              <thead>
                <tr>
                  <th scope="col">Sn</th>
                  <th scope="col">Winners</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  const dt = item.createdAt.split("T");
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <button
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                          className="btn btn-primary"
                          onClick={() => _setCurrentPlayed(item)}
                        >
                          view
                        </button>
                      </td>
                      <td>{dt[0]}</td>
                      <td>{dt[1]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <footer id="footer">
        <div class="container py-4 justify-content-center">
          <ul className="d-flex list-unstyled justify-content-center ">
            <li className="pl-3">
              <span>
                <Link to="/">Home</Link>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/about">About Social Pick</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/privacy">Privacy Policy</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/termsconditions">Terms & Conditions</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/rules">How To Play</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                <a href="/contact">Contact Us</a>
              </span>
            </li>
          </ul>
        </div>
        <SocialIcons />
        <div className="container text-center">
          <p className="">&copy; Copyright . All Rights Reserved</p>
        </div>
      </footer>

      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <h3>
                    Winning Numbers :{" "}
                    <span className="font-weight-bold">
                      {JSON.stringify(currentPlayed.winningnumber)} 
                    </span>{" "}
                  </h3>
                  <table class="table table-dark">
                    <thead>
                      <tr>
                        <th scope="col">Sn</th>
                        <th scope="col">Played Numbers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPlayed?.totalplayed?.map((item, index) => {
                        return (
                          <tr>
                            <td scope="col">{index + 1}</td>
                            <td scope="col">{JSON.stringify(item.numbers)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">footer</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Winners;
