import HelperMethod from "../utils/helper";
import { useNavigate } from "react-router-dom";

const WebProtected = ({ children }) => {
  const token = localStorage.getItem("wtk");
  const isLogenIn = HelperMethod.jwtVerify(token);
  if (isLogenIn) {
    return <>{children}</>;
  } else { 
    return (window.location.href = "/");
  }
};

export default WebProtected;
 