import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear the token from local storage
        localStorage.removeItem('al');
        // Redirect to the login page
        navigate("/agent/login");
    }, [navigate]); // use navigate in the dependencies array

    return null; // Render nothing for this component
}

export default Logout;
