import { useState, useEffect } from "react";
import ApiServices from "../../utils/services";
import { useNavigate } from "react-router-dom";
import HelperMethod from "../../utils/helper";
import Modal from './Modal';

const AgentLogin = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({
      email: "",
      password: "",
    });
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const validateInput = (name, value) => {
      let error = "";
      if (!value) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      } else {
        if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
      }
      setErrors(prev => ({ ...prev, [name]: error }));
      return error;
    };

    const inputChangeHandler = (e) => {
      const { name, value } = e.target;
      validateInput(name, value);
      setUser(prev => ({ ...prev, [name]: value }));
    };

    const loginHandler = async () => {
      const emailError = validateInput("email", user.email);
      const passwordError = validateInput("password", user.password);
      if (!emailError && !passwordError) {
        const data = await ApiServices.agentLogin(user);
        console.log(data)
        if (data?.status === 200) {
          localStorage.setItem("al", data.token);
          navigate("/agent/dashboard");
        } else if(data?.status === 400){
          setModalMessage("Please Enter Valid Credentials");
          setIsModalOpen(true);
        }
        else {
          
          console.log(data.message)
          setModalMessage(data.message);
          setIsModalOpen(true);
        }
      }
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
      (async () => {
        if (HelperMethod.jwtVerify(localStorage.getItem("al"))) {
          navigate("/agent/dashboard");
        }
      })();
    }, [navigate]);

    return (
      <div className="container">
      { isModalOpen ? <Modal isOpen={isModalOpen} message={modalMessage} onClose={closeModal} />: 
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Agent Login</h1>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                          name="email"
                          onChange={inputChangeHandler}
                          value={user.email}
                        />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Password"
                          name="password"
                          onChange={inputChangeHandler}
                          value={user.password}
                        />
                        {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                      </div>
  
                      <button
                        className="btn btn-primary btn-user btn-block"
                        onClick={loginHandler}
                      >
                        Login
                      </button>
                      <hr />
                      <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '', borderRadius: '5px' }}>
  Haven't registered? <a href="/agent/signup" style={{ color: '#007bff', textDecoration: 'none' }}>Signup</a>
  <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '', borderRadius: '5px' }}>
  Forgot your password? <a href="/agent/forgotpassword" style={{ color: '#007bff', textDecoration: 'none' }}>Click here</a>
</div>
</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
}

export default AgentLogin;
