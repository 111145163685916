import React from 'react';
import { Link } from 'react-router-dom';
import WebThemeProvider from './WebThemeProvider';

const Rules = () => {
  return (
    <WebThemeProvider>
      <div>
        <section className="">
          <div className="container innerpageContent">
            <div className="card-body mt-4" style={{ paddingBottom: "26vh" }}>
              <div className="mb-2">
                <label htmlFor="">
                  <h2>
                    <strong>How to Play the Game</strong>
                  </h2>

                  <p>
                    <strong>SIGN UP:</strong> Individuals log in to (Adolphustable.com), providing their full name, email, telephone number, country, city, and the ID number of the representative who referred them if one is available. If an individual signs up through social media and doesn't have a representative, we'll provide one for them (only applicable for individuals in Nigeria).
                  </p>

                  <p>
                    <strong>MAKE A PAYMENT:</strong> For individuals outside of Africa, they can make a donation payment using their debit or credit card. The website will automatically allow them to pick their own number without needing a representative. However, for individuals in Africa, our representatives play a crucial role in collecting the 100 Nairas to play the game.
                  </p>

                  <p>
                    <strong>PICK A NUMBER:</strong> Individuals can now log in and pick any number between 1 and 1,000,000. The player with the lowest unduplicated number wins the game.
                  </p>

                  <p>
                    <strong>STRATEGY TO PLAY:</strong> Social Pick is a skilled game and a game of chance. For example, if two players pick the number 57, the number 57 loses. But if one player picks the number 58 and every other number below 58 which are (57 - 1) has been picked by two people or more, the one player who picked 58 wins. (58) is the lowest unduplicated number, they win!
                  </p>

                  <h3 className="mt-4">
                    <strong>Administrator Dashboard: A Comprehensive Overview</strong>
                  </h3>
                  
                  <p>
                    The administrator dashboard, located in the main back room of the game website, offers a range of features to manage and customize the game experience. One of the key features is the ability to allow more than one winner for each draw. This can be set to one person, two people, three people, or any other number.
                  </p>

                  <p>
                    With just one click of a button, the winner of the draw will be revealed, making it easy and efficient to manage the game. Additionally, an automated email will be sent to the winner.
                  </p>

                  <h3 className="mt-4">
                    <strong>Key Points:</strong>
                  </h3>

                  <p>
                    To cater for a diverse player base, the game offers translations in over 98 different languages, ensuring that players from around the world can enjoy the game in their native language. Furthermore, the global game can be isolated to create a local game experience, allowing administrators to tailor the game to specific regions or communities without affecting the global game selection.
                  </p>

                  <p>
                    Individuals who win have to send us a short video acknowledging they received their winnings. We need to post these videos on our social media platforms to spread the good news of marketing our game to the world.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>
    </WebThemeProvider>
  );
};

export default Rules;
