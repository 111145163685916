import { useState, useEffect } from 'react';
import WebThemeProvider from './WebThemeProvider';
import ApiServices from '../../utils/services';
import HelperMethod from '../../utils/helper';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
var $ = require('jquery');

const Profile = () => {
	const [country, setCountry] = useState([]);
	const [state, setStates] = useState([]);
	const [city, setCity] = useState([]);
	const [data, setData] = useState([])
	const [user, setUser] = useState({
		username: '',
		email: '',
		country: '',
		state: '',
		city: '',
		number: '',
	});

	//console.log('profile user????????????>', user.state);
	const fetchUser = async () => {
		try {
			const token = HelperMethod.jwtVerify(localStorage.getItem('wtk'));
			if (token) {
				const { data } = await ApiServices.getUserDetails(token.data.email);
				console.log(' Fetch User data>>>>>', data);
				const stateData = await ApiServices.getState({
					country: data.country,
				});
				setStates(stateData.data[0].states);
				const cityData = await ApiServices.getCities({
					country: data.country,
					state: data.city,
				});

				setCity(cityData.datas[0].cities);
       			setUser({
					username: data.username,
					email: data.email,
					country: data.country,
					state: data.city,
					city: data.state,
					number: data.number,
				});
			} else {
				// console.log('texp');
			}
		} catch (error) {}
	};
	// const onChangeHandler = (e) => {
	//   setUser({ ...user, [e.target.name]: e.target.value });
	// }

	const fetchCountry = async () => {
		const data = await ApiServices.getCountry();
		setCountry(data.data);
	};

	const formCouHandler = async (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });

		if (e.target.name === 'country') {
			const selectedCountry = e.target.value;
			try {
				const data = await ApiServices.getState({
					country: selectedCountry,
				});
				setStates(data.data[0].states);
			} catch (error) {
				// console.error('Error fetching states:', error);
			}
		} else if (e.target.name === 'state') {
			const selectedState = e.target.value;
			const selectedCountry = user.country;

			try {
				const cityData = await ApiServices.getCities({
					country: selectedCountry,
					state: selectedState,
				});

				setCity(cityData.datas[0].cities);
				// console.log('city .....', cityData.datas[0].cities);
			} catch (error) {
				// console.error('Error fetching cities:', error);
			}
		}
	};
	const fetchHistoryData = async ()=>{
		const token = HelperMethod.jwtVerify(localStorage.getItem('wtk'));
		const data = await ApiServices.gamePlayedHostory(token.data.email);
		console.log("dd", data);
		setData(data.data);
		setTimeout(() => {
			$('#dataTable').DataTable();
		}, 1000)


	}
	const updateUser = async () => {
		const token = HelperMethod.jwtVerify(localStorage.getItem('wtk'));
		const data = await ApiServices.updateUserDetails({
				username: user.username,
				email: user.email,
				country: user.country,
				state: user.city,
				city: user.state,
				number: user.number,
				email: token.data.email,
		});
		if (data.status === 200) {
			alert('Profile updated !');
		}
	};
	useEffect(() => {
		fetchUser();
		fetchCountry();
		fetchHistoryData();
	}, []);

 


	return (
		<>
			<WebThemeProvider>
				<div className="container mb-5">
					<div className="row mt-5 mb-5">
						<div className="col-md-12 mt-5">
							<h3> Update Pofile </h3>
						</div>
						<div className="col-md-6">
							<p> Username: </p>
							<input
								type="text"
								value={user.username}
								disabled
								className="form-control"
							/>
						</div>
						<div className="col-md-6">
							<p> Email: </p>
							<input
								type="text"
								value={user.email}
								disabled
								className="form-control"
							/>
						</div>
						<div className="col-md-6">
							<p> Country: </p>
							{/* <input type="text" value={user.country} name="country" onChange={(e) => formCouHandler(e)} className="form-control" /> */}
							<select
								name="country"
								onChange={(e) => formCouHandler(e)}
								class="form-control form-control-user"
								value={user.country}
							>
								<option >Choose country..</option>
								{country.map((item) => {
									return (
										<option value={item.name} key={item._id}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
						<div className="col-md-6">
							<p> State:  </p>
							{/* <input type="text" value={user.state} name="state" onChange={(e) => formCouHandler(e)} className="form-control" /> */}
							<select
								name="state"
								onChange={(e) => formCouHandler(e)}
								class="form-control form-control-user"
								value={user.state}
							>
								<option value={user.state}>{user.state}</option>
								{state.map((item) => {
									return (
										<option value={item.name} key={item._id}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
						<div className="col-md-6">
							<p> City: </p>
							{/* <input type="text" value={user.city} name="city" onChange={(e) => formCouHandler(e)} className="form-control" /> */}

							<select
								name="city"
								onChange={(e) => formCouHandler(e)}
								class="form-control form-control-user"
								value={user.city}
							>
								<option value={user.city}>{user.city}</option>
								{city.map((item) => {
									return (
										<option value={item.name} key={item._id}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
						<div className="col-md-6">
							<p> Phone: </p>
							<input
								type="number"
								value={user.number}
								name="number"
								onChange={(e) => formCouHandler(e)}
								className="form-control"
							/>
						</div>
						<div className="col-md-8 mt-3 mb-5">
							<button className="btn btn-success mb-3" onClick={updateUser}>
								{' '}
								Update{' '}
							</button>
						</div>
					</div>
				</div>
				<div class="container">
        <div class="col-xl-12 col-lg-12">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Total Game Played : { data.length }</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table
                  class="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Played Numbers</th>
                      <th>Paid Amount</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      const dt = item.createdAt.split("T");
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td> {JSON.stringify(item?.numbers)} </td>
                          <td> {item?.numbers.length > 1 ? "$" + item?.numbers.length / 2 : "Free"}</td>
                          <td>{dt[0]}</td>
                          <td>{dt[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
			</WebThemeProvider>
		</>
	);
};

export default Profile;
