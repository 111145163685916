import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiServices from '../../utils/services';
import './sidebar.css';

const AgentReports = () => {
  const [agentList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterInput, setFilterInput] = useState("");

  const fetchAgents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ApiServices.agentlist();
      if (response.status === 200) {
        console.log("Agent list:", response.data);

        const earningsResponse = await ApiServices.fetchEarnings({ startDate, endDate });
        console.log("Earnings results:", earningsResponse);

        const agentsWithData = response.data.map(agent => {
          if (earningsResponse.status === 200 && earningsResponse.data) {
            const earningsData = earningsResponse.data.find(e => e._id === agent._id);
            const totalEarnings = earningsData ? earningsData.totalCashTaken : 0;
            if (earningsData) {
              console.log(`Earnings data found for agent ${agent._id}:`, earningsData.totalCashTaken);
            } else {
              console.log(`No earnings data found for agent ${agent._id}`);
            }
            return { ...agent, earnings: totalEarnings };
          } else {
            console.log(`Failed to fetch earnings or no earnings data available`);
            return { ...agent, earnings: 0 };
          }
        });

        console.log("Agents with data:", agentsWithData);
        setAgentList(agentsWithData);
        setLoading(false);
      } else {
        setError('Failed to fetch agents');
        setLoading(false);
      }
    } catch (err) {
      console.error("Error in fetchAgents:", err);
      setError(err.message);
      setLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const columns = useMemo(() => [
    { Header: 'Representative ID', accessor: 'username' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Earnings', accessor: 'earnings' },
    { Header: 'Created At', accessor: 'createdAt', Cell: ({ value }) => new Date(value).toLocaleDateString() }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    { columns, data: agentList, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    usePagination
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Agent List</h6>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                />
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                />
              </div>
              <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder="Search agents..."
                className="form-control"
              />
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table {...getTableProps()} className="table table-bordered">
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* Pagination controls */}
                <div className="pagination" style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                  marginBottom: '20px'
                }}>
                  <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    style={{
                      cursor: 'pointer',
                      padding: '10px 20px',
                      marginRight: '10px',
                      backgroundColor: '#007bff',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      opacity: !canPreviousPage ? 0.5 : 1
                    }}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    style={{
                      cursor: 'pointer',
                      padding: '10px 20px',
                      marginLeft: '10px',
                      backgroundColor: '#28a745',
                      color: 'white',
                      border: 'none',
                      borderRadius: '5px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      opacity: !canNextPage ? 0.5 : 1
                    }}
                  >
                    Next
                  </button>
                  <span style={{
                    marginLeft: '20px',
                    fontSize: '16px',
                    color: '#343a40'
                  }}>
                    Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentReports;
