import React from 'react';
import WebThemeProvider from './WebThemeProvider';

const About = () => {

  return (
    <WebThemeProvider>
      <div>
        <section className="">
          <div className="container innerpageContent">
            <div className="card-body mt-5" style={{ paddingBottom: "54vh" }}>
              <div className="mb-2">
                <label htmlFor="">
                  <p>ABOUT OUR SOCIAL PICK GAME:</p>
                  <p>
                    Social Pick was originally created in the United States as a free game, where players in the network could win cool prizes. However, due to the high cost of shipping merchandise to individual winners worldwide, we provided virtual visa gift cards. Due to the overwhelming cost to manage and maintain the game website, there is a small fee that individuals have to pay to play the game. In addition, half of the funds are donated to a local non-profit homeless shelter organization.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>
    </WebThemeProvider>
  );
};

export default About;
