import React, { useState, useEffect } from 'react';
import ApiServices from "../../utils/services";
import { useNavigate } from "react-router-dom";
import Modal from './Modal';
// comment das
const Signup = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    country: '',
    state: '',
    city: '',
    number: ''
  });
  const [country, setCountry] = useState([]);
  const [state, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errors, setErrors] = useState({});
// sample comment
  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "username":
        if (!value) error = "Username is required";
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid";
        }
        break;
      case "password":
        if (!value) {
          error = "Password is required";
        } else if (value.length < 8) {
          error = "Password must be at least 8 characters";
        }
        break;
      case "country":
      case "state":
      case "city":
        if (!value) error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [name]: error }));
    return error;
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/agent/login");
  };

  const formHandler = (e) => {
    const { name, value } = e.target;
    validateInput(name, value);
    setUser(prev => ({ ...prev, [name]: value }));
  };

  const fetchCountry = async () => {
    try {
      const response = await ApiServices.getCountry();
      setCountry(response?.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const formCouHandler = async (e) => {
    const { name, value } = e.target;
    setUser(prev => ({ ...prev, [name]: value }));

    if (name === 'country') {
      try {
        const response = await ApiServices.getState({ country: value });
        setStates(response?.data[0].states);
        setCity([]); // Clear cities on country change
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    } else if (name === 'state') {
      try {
        const response = await ApiServices.getCities({
          country: user.country,
          state: value,
        });
        setCity(response.datas[0].cities);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    }
  };

  const signUpHandler = async () => {
    const formErrors = Object.keys(user).map(key => validateInput(key, user[key])).filter(error => error);
    if (formErrors.length === 0) {
      try {
        const response = await ApiServices.agentUserSignUp({
          email: user.email,
          password: user.password,
          username: user.username,
          country: user.country,
          state: user.city,
          city: user.state,
          number: user.number,
        });
        if (response.status === 201) {
          setModalMessage("You are Registered Successfully, Our Team is reviewing your Account and you will be notified soon ");
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      {isModalOpen ? (
        <Modal isOpen={isModalOpen} message={modalMessage} onClose={closeModal} />
      ) : (
        <div className="row justify-content-center" style={{ marginTop: '20px' }}>
          <div className="col-lg-6">
            <div style={{ padding: '25px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '10px', backgroundColor: '#fff' }}>
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ color: '#333', fontWeight: '600' }}>Agent Register</h3>
                <p style={{ fontSize: '16px', color: '#555', marginBottom: '20px' }}>Fill in the details to complete your registration.</p>
                <input type="text" className="form-control form-control-user" placeholder="Enter Representative ID" name="username" onChange={formHandler} value={user.username} style={{ marginBottom: '10px' }} />
                {errors.username && <p style={{ color: 'red' }}>{errors.username}</p>}
                <input type="email" className="form-control form-control-user" placeholder="Enter Email Address..." name="email" onChange={formHandler} value={user.email} style={{ marginBottom: '10px' }} />
                {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                <input type="password" className="form-control form-control-user" placeholder="Password" name="password" onChange={formHandler} value={user.password} style={{ marginBottom: '10px' }} />
                {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                <select name="country" onChange={formCouHandler} className="form-control form-control-user" value={user.country} style={{ marginBottom: '10px' }}>
                  <option>Choose country...</option>
                  {country.map(item => <option value={item.name} key={item._id}>{item.name}</option>)}
                </select>
                {errors.country && <p style={{ color: 'red' }}>{errors.country}</p>}
                <select name="state" onChange={formCouHandler} className="form-control form-control-user" value={user.state} style={{ marginBottom: '10px' }}>
                  <option>Choose state...</option>
                  {state.map(item => <option value={item.name} key={item._id}>{item.name}</option>)}
                </select>
                {errors.state && <p style={{ color: 'red' }}>{errors.state}</p>}
                <select name="city" onChange={formCouHandler} className="form-control form-control-user" value={user.city} style={{ marginBottom: '10px' }}>
                  <option>Choose city...</option>
                  {city.map(item => <option value={item.name} key={item._id}>{item.name}</option>)}
                </select>
                {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                <input type="text" className="form-control form-control-user" placeholder="Phone" name="number" onChange={formHandler} value={user.number} style={{ marginBottom: '20px' }} />
                {errors.number && <p style={{ color: 'red' }}>{errors.number}</p>}
                <button className="btn btn-primary btn-user btn-block" onClick={signUpHandler} style={{ padding: '10px', fontSize: '18px', fontWeight: '600' }}>Register</button>
              </div>
            </div>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '5px' }}>
              Already registered? <a href="/agent/login" style={{ color: '#007bff', textDecoration: 'none' }}>Login</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
