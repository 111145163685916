import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Button, CircularProgress, Box, Container, Typography } from '@mui/material';
import ApiServices from '../../utils/services';
import jwt_decode from "jwt-decode";
import './sidebar.css'; // Assuming you have some global styles here

const UserTable = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterInput, setFilterInput] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await ApiServices.webUserList();
        if (response.status === 200) {
          setUserList(response.data.map(user => ({
            ...user,
            editableChances: user.totalnumbers || 0,
            cashTaken: user.cashTaken || 0 // Assuming the user model has a cashTaken field
          })));
        } else {
          setError('Failed to fetch users');
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
  
    fetchUsers();
  }, []);

  const handleEditCellChange = useCallback(
    ({ id, field, value }) => {
      setUserList((prevList) => 
        prevList.map((row) => 
          row.id === id ? { ...row, [field]: value } : row
        )
      );
    },
    []
  );

  const saveChances = async (row) => {
    const token = localStorage.getItem('al');
    const decoded = await jwt_decode(token);
    const agentId = decoded.user.id;
    const body = {
      "userId": row.id,
      "agentId": agentId,
      "userEmail": row.email,
      "previousChances": row.totalnumbers,
      "cashTaken": row.cashTaken
    };
    const response = await ApiServices.ledgerChanges(body);
    // Reload the page to reflect changes
    window.location.reload();
  };

  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
  };

  const filteredRows = userList.filter(user => 
    user.username.toLowerCase().includes(filterInput.toLowerCase()) ||
    user.email.toLowerCase().includes(filterInput.toLowerCase())
  );

  const columns = [
    { field: 'id', headerName: 'Sn.', width: 70 },
    { field: 'username', headerName: 'User Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'country', headerName: 'Country', width: 130 },
    { field: 'state', headerName: 'State', width: 130 },
    { field: 'city', headerName: 'City', width: 130 },
    { field: 'number', headerName: 'Phone', width: 150 },
    { field: 'createdAt', headerName: 'Registration Date', width: 180 },
    { field: 'editableChances', headerName: 'Current Chances', width: 180, editable: true },
    {
      field: 'cashTaken',
      headerName: 'Enter Cash Taken',
      width: 180,
      editable: true,
      renderCell: (params) => (
        <TextField
          type="number"
          value={params.value}
          onChange={(e) => handleEditCellChange({
            id: params.id,
            field: 'cashTaken',
            value: e.target.value
          })}
        />
      )
    },
    {
      field: 'save',
      headerName: 'Edit',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          onClick={() => saveChances(params.row)}
        >
          Save
        </Button>
      )
    }
  ];

  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          User List: {userList.length}
        </Typography>
        <Box mb={2}>
          <TextField
            value={filterInput}
            onChange={handleFilterChange}
            placeholder="Search users..."
            variant="outlined"
            fullWidth
          />
        </Box>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={8}
            rowsPerPageOptions={[8]}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(row) => row._id} // Use the unique identifier from your data
          />
        </div>
      </Box>
    </Container>
  );
};

export default UserTable;
