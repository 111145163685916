import { useState, useEffect } from "react";
import ApiServices from "../../utils/services";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
var $ = require('jquery');


const GamePlayed = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const data = await ApiServices.gamePlayed();
    console.log(data);
    setData(data.data);
    setTimeout(() => {
      $('#dataTable').DataTable();
  }, 1000)
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Game Played : { data.length }</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table
                  class="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>City</th>
                      <th>Played Numbers</th>
                      <th>Paid Amount</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      const dt = item.createdAt.split("T");
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td> { item?.email } </td>
                          <td> { item?.country }</td>
                          <td> { item?.state }</td>
                          <td> {JSON.stringify(item?.numbers)} </td>
                          <td> {item?.graceGiver ? "$" + item?.numbers.length / 2 : "Free"}</td>
                          <td>{dt[0]}</td>
                          <td>{dt[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePlayed;
