import { useState, useEffect } from "react";
import ApiServices from "../../utils/services";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import './sidebar.css';
var $ = require('jquery');

const UserList = () => {
  const [userlist, setUserList] = useState([]);
  const [currentPlayed, setCurrentPlayed] = useState([])
  const fetchUsers = async () => {
    const data = await ApiServices.webUserList();
    console.log(data);
    if (data.status === 200) {
      setUserList(data.data);
    }
    setTimeout(() => {
      $('#dataTable').DataTable();
  }, 1000)
  };

  const _setCurrentPlayed = (email) => {
     const findCurrent = userlist.filter(item => item.email === email);
     setCurrentPlayed(findCurrent[0].game_payed);
  }
  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">User List : {userlist.length}</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
              <table className="table table-bordered" id="dataTable" width="100%" >
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th> View Game Played</th>
                      <th>Representative ID</th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>State</th>
                      <th>City</th>
                      <th>Phone</th>
                      <th>Registration Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userlist.map((item, index) => {
                      return (
                        <tr>
                          <td>{index+1}</td>
                          <td>
                            <button className="btn btn-primary"
                             data-toggle="modal"
                             data-target=".bd-example-modal-lg"
                             onClick={() => _setCurrentPlayed(item.email)}
                            >View</button>
                          </td>
                          <td>{item.reffered_by}</td>
                          <td>{item.username}</td>
                          <td>{item.email}</td>
                          <td>{item.country}</td>
                          <td>{item.city}</td>
                          <td>{item.state}</td>
                          <td>{item.number}</td>
                          <td>{item.createdAt}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="font-weight-bold">Total Game Played  : { currentPlayed.length }  </h3>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sn</th>
                        <th scope="col">Played Numbers</th>
                        <th>Paid Amount</th>
                        <th>Played Date</th>
                       
                       </tr>
                    </thead>
                    <tbody>
                      {currentPlayed?.map((item, index) => {
                        return (
                          <tr>
                            <td scope="col">{index + 1}</td>
                            <td scope="col">{JSON.stringify(item?.numbers)}</td>
                            <td>  {item?.numbers.length > 1 ? "$" + item?.numbers.length / 2 + " Paid" : "Free"} </td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">footer</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
