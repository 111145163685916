import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WebThemeProvider from "./WebThemeProvider";
import ApiServices from "../../utils/services";

const PersonalQualification = () => {
  const [loading, setLoading] = useState(false);
  const [conatctData, setContactData] = useState({
    "fname": "",
    "lname": "",
    "email": "",
    "phone": "",
    "city": "",
    "state": "",
    "country": "",
    "haveyouplayed": "",
    "prefered": "",
    "explain": ""
  });
  const onChangeHandler = (e) => {
    setContactData({ ...conatctData, [e.target.name]: e.target.value });
  }
  const sendMessage = async () => {
    // console.log(conatctData);
    const { fname, lname, email, phone, city, state, country, haveyouplayed, prefered, explain } = conatctData;
    if (fname != "", lname != "", email != "", phone != "", city != "", state != "", country != "", haveyouplayed != "", prefered != "", explain != "") {
      setLoading(true);
      const data = await ApiServices.sentQualificationEmail(conatctData);
      // console.log(data);
      if (data.status === 200) {
        alert(`
       "THANK YOU FOR APPLYING TO SEE IF YOU QUALIFY 
        TO SIT AT ADOLPHUS TABLE. IT CAN TAKE UP TO 
        72 HOURS TO GIVE YOU A RESPONSE. WE WILL 
        RESPOND TO YOU WITH A YES OR NO TO YOUR 
        REQUEST.”
      `);
      }
      setLoading(false);
    } else {
      alert("Please fill all the fields !");
    }
  }
  return (
    <WebThemeProvider>
      <div>
        <section className="">
          <div class="container">
            <section class="">
              <h2 class="h1-responsive font-weight-bold text-center">
                 Qualifications
              </h2>
              <p class="text-center w-responsive mx-auto mb-5">
                Do you have any questions? Please do not hesitate to contact us
                directly. Our team will get back to you within a matter of
                hours to help you.
              </p>

              <div class="row">
                <div class="col-md-12 mb-md-0 mb-5">
                  <form
                    id="contact-form"
                    name="contact-form"
                    action="mail.php"
                    method="POST"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="mt-1">
                            FIRST NAME*
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="fname"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.fname}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="name" class="mt-1">
                            LAST NAME*
                          </label>
                          <input
                            type="text"
                            id="ss"
                            name="lname"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.lname}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="email" class="mt-1">
                            EMAIL*
                          </label>
                          <input
                            type="text"
                            id="email"
                            name="email"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.email}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="email" class="mt-1">
                            PHONE*
                          </label>
                          <input
                            type="number"
                            id="number"
                            name="phone"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.phone}
                          />
                        </div>
                      </div>


                      {/* <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="">
                            How do you hear about us?
                          </label>
                          <input
                            type="text"
                            id="subject"
                            name="hearabout"
                            class="form-control"
                            onChange={(e)=>onChangeHandler(e)}
                          />
                        </div>
                      </div> */}
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="mt-1">
                            COUNTRY*
                          </label>
                          <input
                            type="text"
                            id="subject"
                            name="country"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.country}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="mt-1">
                            STATE*
                          </label>
                          <input
                            type="text"
                            id="subject"
                            name="state"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.state}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="mt-1">
                            CITY*
                          </label>
                          <input
                            type="text"
                            id="subject"
                            name="city"
                            class="form-control"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.city}
                          />
                        </div>
                      </div>



                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="mt-1">
                            HAVE YOU PLAYED THE GRACE GIVER VERSION OF THE GAME?*
                          </label>
                          <select class="form-select"
                            value={conatctData.haveyouplayed}
                            name="haveyouplayed" onChange={(e) => onChangeHandler(e)} aria-label="Default select example">
                            <option >Choose...</option>
                            <option value="Yes">YES</option>
                            <option value="No">NO</option>

                          </select>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="mt-1">
                            WHAT IS THE PROMOTER’S NAME WHO REFERRED YOU?*
                          </label>
                          <textarea
                            type="text"
                            id="messagdde"
                            name="prefered"
                            rows="2"
                            class="form-control md-textarea"
                            onChange={(e) => onChangeHandler(e)}
                            value={conatctData.prefered}
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form mb-0">
                          <label for="subject" class="mt-1">
                            BRIEFLY EXPLAIN WHY YOU WANT TO SIT AT  ADOLPHUS TABLE*
                          </label>
                          <textarea
                            type="text"
                            id="messagde"
                            name="explain"
                            rows="2"
                            class="form-control md-textarea"
                            value={conatctData.explain}
                            onChange={(e) => onChangeHandler(e)}
                          ></textarea>
                        </div>
                      </div>



                    </div>



                  </form>

                  <div class="mt-2">
                    <button
                      class="btn btn-primary sysbtn"
                      onClick={sendMessage}
                    >
                      {loading ? "sending..." : "Send"}
                    </button>
                  </div>
                  <div class="status"></div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </WebThemeProvider>
  );
};

export default PersonalQualification;


// medical
// real-state
// 