import React, { useState, useEffect} from 'react';
import Agentlogin from './agentlogin';
import './sidebar.css';
import UserTable from './UserTable';
import Logout from './Logout';
import jwtDecode from 'jwt-decode';


function Sidebar() {
  const [activeTab, setActiveTab] = useState('Home');
  const [userName, setUserName] = useState('');

  const renderComponent = () => {
    switch (activeTab) {
      case 'Home':
        return <UserTable/>;
      case 'Logout':
        return <Logout />;
      default:
        return <Agentlogin />;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('al');
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken)
      setUserName(decodedToken.user.username); // Adjust 'username' based on your token's payload structure
    }
  }, [])
  return (
    <div className="sidebar-container">
      <div className="sidebar">
        {userName && (
          <div className="username-display">
            Welcome, {userName}
          </div>
        )}
        <button onClick={() => setActiveTab('Home')}>Users</button>
        <button onClick={() => setActiveTab('Logout')}>Logout</button>
      </div>
      <div className="content">
        {renderComponent()}
      </div>
    </div>
  );
}

export default Sidebar;
