import { useState, useEffect,useRef } from "react";
import ApiServices from "../../utils/services";
import HelperMethod from "../../utils/helper";
import { Link } from "react-router-dom";
import WebHeader from "../../components/webTheme/header";
import SocialIcons from "../../components/ui/socialIcons";
import { config } from "../../utils/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PaymentModal from './PaymentModal'


const Home = () => {
  const [country, setCountry] = useState([]);
  const [state, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [graceGiver, setGraceGiver] = useState(false); 
  const [payment,setpayment]=useState(false);
  const navigate = useNavigate();
  const [isActive, setIsActve] = useState({
    isLogedIn: false,
    username: "",
    email: "",
  });
  const [switchModel, setSwitchModel] = useState({
    signin: false,
    signup: true,
    forgotpassword:false,
    verifyOtp:false,
    resetpassword:false,
    changed:false
  });
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    country: "",
    state: "",
    otp: "",
    number: "",
    city: "",
    refferedby:""
  });
  const [isPaid, setisPaid] = useState({
    isPaid: false,
    numbers: 1,
    gracenumber:0
  });
  const [listedNumber, setListedNumber] = useState([]);
  const [holdNumbers, setHoldNumbers] = useState([]);
  const [chooseNumber, setChooseNumber] = useState([]);
  const [isgame, setIsgame] = useState(false);
  const [gameLoad, setGameLoad] = useState(false);
  const [addNumber, setAddNumber] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const formHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const [fuser, fsetUser] = useState({
    otp:'',
    newPassword: '',
    confirmNewPassword: '',
  });

  const forgotformHandler = (e) => {
    fsetUser({ ...fuser, [e.target.name]: e.target.value });
    console.log(fuser)
  };


  const formCouHandler = async (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    //console.log({ [e.target.name]: e.target.value });

    if (e.target.name === "country") {
      const selectedCountry = e.target.value;
      try {
        const data = await ApiServices.getState({
          country: selectedCountry,
        });
        setStates(data.data[0].states);
      } catch (error) {
        //console.error("Error fetching states:", error);

      }
    } else if (e.target.name === "state") {
      const selectedState = e.target.value;
      const selectedCountry = user.country;

      try {
        const cityData = await ApiServices.getCities({
          country: selectedCountry,
          state: selectedState,
        });

        setCity(cityData.datas[0].cities);
        // console.log("city .....", cityData.datas[0].cities);
      } catch (error) {
        // console.error("Error fetching cities:", error);

      }
    }


  };




  const signUpHandler = async () => {
    if (user.email !== "" && user.password !== "" && user.username !== "" && user.country !== "" && user.city !== "" && user.state !== "" && user.number !== "") {
      const data = await ApiServices.webUserSignUp({
        email: user.email,
        password: user.password,
        username: user.username,
        country: user.country,
        state: user.city,
        city: user.state,
        number: user.number,
        refferedby:user.refferedby
      });
      if (data.status === 201) {
        alert("User created successfully!");
        localStorage.setItem("wtk", data.token);
        setIsActve({
          isLogedIn: true,
          email: user.email,
          username: user.username,
        });
        setisPaid({
          ...isPaid,
          numbers: 1,
        });
        window.location.reload()
      } else if (data.status === 200) {
        alert(data.message);
      }
      // console.log(data);
    } else {
      alert("Please fill all fields");
    }
  };
  const signInHandler = async () => {
    if (user.email && user.password) {
      const data = await ApiServices.webUserSignIn({
        email: user.email,
        password: user.password,
      });
      if (data.status === 200) {
        window.location.reload()
        localStorage.setItem("wtk", data.token);
        setIsActve({
          isLogedIn: true,
          email: user.email,
          password: user.password,
        });
        const isUser = HelperMethod.jwtVerify(localStorage.getItem("wtk"));
        if (isUser) {
          setisPaid({
            ...isPaid,
            gracenumber:isUser.data.totalnumbers
          });
        }
        fetchIsGamePlayed(true, user.email);
      } else {
        alert(data.message);
      }
      // console.log(data);
    } else {
      alert("please fill both the fields");
    }
  };

  const checkUser = async () => {
    const isUser = HelperMethod.jwtVerify(localStorage.getItem("wtk"));
    if (isUser) {
      setIsActve({
        isLogedIn: true,
        username: isUser.data.username,
        email: isUser.data.email,
      });
      setisPaid({
        ...isPaid,
        gracenumber: isUser.data.totalnumbers,
      });
      await fetchIsGamePlayed(true,isUser.data.email);
      const { data } = await ApiServices.getUpdatedNumbers(isUser.data.email);
      if (data?.length > 0) {
        console.log("ddd", data[0]?.totalnumbers);
        setisPaid({ ...isPaid, gracenumber: data[0]?.totalnumbers });
      }
    }
    // console.log(isUser);

  };
  let totalNumbers = [...Array(1000).keys()];
  totalNumbers.shift();


  const fetchCountry = async () => {
    const data = await ApiServices.getCountry();
    setCountry(data.data);
  };


  // console.log(totalNumbers, "ss");
  useEffect(() => {
    checkUser();
    fetchCountry();
    
    const chunkedNumbers = chunk(totalNumbers, 10);
    setHoldNumbers(chunkedNumbers);
    setListedNumber(chunkedNumbers.slice(0, 10));
    
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('pp');
    if (myParam === 'success') {
      setpayment(true)
    }
  
  }, [graceGiver]);
  function chunk(arr, size) {
    let result = arr.reduce(
      (rows, key, index) =>
        (index % size == 0
          ? rows.push([key])
          : rows[rows?.length - 1].push(key)) && rows,
      []
    );
    return result;
  }
  //console.log(listedNumber);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [next, setNext] = useState(10);
  const [prev, setPrev] = useState(0);
  const [btnStatus, setBtnStatus] = useState(1);
  const nextHandler = () => {
    // Check if advancing the range goes beyond 999
    console.log(next)
    if (next + 10 <= 999) {
      setCurrentIndex(currentIndex + 1);
      setNext(next + 10);
      setPrev(prev + 10);
      const currentNumbers = holdNumbers.slice(prev + 10, next + 10);
      setListedNumber(currentNumbers);
    }
  };
  const prevHandler = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
      setNext(next - 10);
      setPrev(prev - 10);
      const currentNumbers = holdNumbers.slice(prev - 10, next - 10);
      setListedNumber(currentNumbers);
    }
  };
  const pickNumber = (num) => {
    // Check if graceGiver is true, use isPaid.numbers, otherwise use 1 for the comparison
    const maxNumbersAllowed = graceGiver ? isPaid.gracenumber : 1;

    if (chooseNumber?.length < maxNumbersAllowed) {
        if (num <= 1000000) {
            if (chooseNumber.includes(num)) {
                alert("This number is already selected!");
            } else {
                setChooseNumber([...chooseNumber, num]);
            }
        } else {
            alert("You cannot choose a number greater than one million");
        }
    } else {
        alert(`You have already selected your maximum allowed numbers: ${maxNumbersAllowed}`);
    }
};
  const deleteChooseNumber = (it) => {
    setChooseNumber(chooseNumber.filter((item) => item !== it));
  };
  const [amt, setAmt] = useState("");
  const amountOnChange = (e) => {
    console.log("ss", e.target.value);
    if (e.target.value !== "0") {
      setAmt(e.target.value);
    } else {
      setAmt("");
      alert("Please enter valid amount")
    }
  }
  const gameNumbersHandler = async () => {
    // console.log(isActive);
    const graceGiverflag=graceGiver?true:false
    let chances=null;
    if(graceGiver){ chances=isPaid?.gracenumber - chooseNumber?.length;}
    
    
    // if(graceGiver){
    //   console.log("Inside ngame number handlers",isPaid.numbers,chooseNumber.length)
    //   const res = await ApiServices.updateWebUserNumber({
    //   email: isActive.email,
    //   totalnumbers: isPaid.numbers - chooseNumber.length,
    // });}
    const data = await ApiServices.saveGameNumbers({
      username: isActive.username,
      email: isActive.email,
      numbers: chooseNumber,
      graceGiver:graceGiverflag,
      chances:chances
    });
  
    if(!data)
    {
      alert("Your chances are exhausted or insufficient, please refresh your page"); 
    }
   
    
    // console.log(res.data)
    //console.log(data);
    else{alert("Numbers saved successfully !");
    setIsgame(true);}
    
  };
  async function fetchIsGamePlayed(isAc, email) {
    if (isAc) {
      setGameLoad(true);
      const data = await ApiServices.isGamePlayed({
        email: email ? email : isActive.email,
      });
      setIsgame(Boolean(data.isGamePlayed));
      // console.log("is", data);
      setGameLoad(false);
    }
  }
  const signOutHandler = () => {
    localStorage.removeItem("wtk");
    setIsActve({
      isLogedIn: false
    });
    navigate("/");
    // window.location.reload();
  };
  const addhandler = () => {
    if (graceGiver?chooseNumber?.length < isPaid.gracenumber:chooseNumber?.length < isPaid.numbers) {
      if (addNumber === 0) {
        alert("0 is not allowed");
      } else {
        if (!addNumber || Math.sign(addNumber) == -1) {
          alert("Please choose any positive number")

        } else {
          if (addNumber <= 1000000) {
            if (chooseNumber.includes(addNumber)) {
              alert("This number is already selected !");
            } else {
              setChooseNumber([...chooseNumber, addNumber]);
            }
          } else {
            alert("You cannot choose a number greater than one million");
          }
        }
      }
    } else {
      alert("You have already selected your maximum allowed numbers");
    }
  };
  const sendOtp = async () => {
    if (user.email !== "" && user.password !== "" && user.username !== "" && user.country !== "" && user.city !== "" && user.state !== "" && user.number !== "") {

      setOtpSent(true);
      setBtnStatus(2);
      const data = await ApiServices.sendOtp({
        email: user.email,
        otp: user.otp,
      });
      if (data.status === 200) {
        alert("OTP Sent Successfully !");
      } else {
        alert("Internal Server error !");
      }
    } else {
      alert("Please fill all the fields !")
    }
    //console.log(data, "sass");
  };
  const verifyOtp = async () => {
    const data = await ApiServices.verifyOtp({
      email: user.email,
      otp: user.otp,
    });
    if (data.status === 200) {
      setOtpSent(false);
      setBtnStatus(3);
    } else if (data.status === 403) {
      alert("You have entered Incorrect OTP");
    } else {
      alert("Internal Server error !");
    }
    // console.log("ise", data);
  };

  const setnumberHandler = (e) => {
    const value = Number(e.target.value);
    const maxNumbersAllowed = graceGiver ? isPaid.gracenumber : 1;
  
    // Check if the input value is within the allowed range and is an integer
    if (value < 1 || value > 1000000 || !Number.isInteger(value)) {
      // Alert and reset the input if the value is outside the allowed range or not an integer
      alert("Please enter a positive integer between 1 and 1,000,000.");
      setAddNumber(""); // Optionally, clear the field or reset to a default valid value
      return; // Exit the function early to avoid further checks
    }
  
    // Assuming 'chooseNumber' should include the current input value, you should check if adding
    // the current number would exceed the maximum allowed numbers.
    // This assumes 'chooseNumber' is an array of already chosen numbers and 'value' is the new number to add.
    // Adjust logic based on how you track/add the chosen numbers.
    if (chooseNumber.includes(value)) {
      alert("This number has already been selected.");
      return;
    }
  
    if (chooseNumber?.length >= maxNumbersAllowed) {
      alert(`You have already selected your maximum allowed numbers: ${maxNumbersAllowed}`);
      return;
    }
  
    // If all checks pass, add the number.
    // This assumes there's logic elsewhere to actually add 'value' to 'chooseNumber'.
    setAddNumber(value);
  };

  const requestOtpHandler=async()=>{
    const data = await ApiServices.sendOtp({
      email: user.email,
      otp: user.otp,
    });
    if (data.status === 200) {
      setSwitchModel({ verifyOtp: true });
      alert("OTP Sent Successfully !");
    } else {
      alert("Internal Server error !");
    }
  } 
  

  const resetPasswordHandler = async () => {
    // Destructuring for ease of use
    const { newPassword, confirmNewPassword, otp } = fuser;
    const email=user.email;
  
    // Basic validation (e.g., checking if passwords match)
    if (newPassword !== confirmNewPassword) {
      alert("Passwords don't match.");
      return; // Stop execution if passwords do not match
    }
  
    try {
      // Make the POST request to your backend endpoint
      // Adjust the URL ('/api/reset-password-with-otp') as needed for your API endpoint
      const response = await axios.post(`${config.apiEndPoint}/web/reset-password-with-otp`, {
        email,
        newPassword,
        otp, // Use the OTP provided by the user
      });
  
      // Check for success response (status code 200)
      if (response.status === 200) {
        alert('Password has been reset successfully.');
        setSwitchModel({ changed: true });
        // Here you can redirect the user or perform other actions on successful password reset
      } else {
        // Handle any other responses appropriately
        alert('Failed to reset password. Please try again.');
      }
    } catch (error) {
      // Log error to console and alert the user
      console.error('Failed to reset password:', error);
      alert('An error occurred. Please try again.');
    }
  };
  const payNow = async () => {
    if (amt === 0) {
      alert("0 is not allowed");
    } else {
      if (!amt || Math.sign(amt) === -1) {
        alert("Please choose any positive number");
      } else {
        if (String(amt).split(".")?.length === 1) {
          if (parseFloat(amt) <= 100) {
            try {
              const response = await axios.get(`${config.apiEndPoint}/web/pay?email=${isActive.email}&amt=${amt}`);
              console.log("got the url URL", response.data.data);
              window.location.href = response.data.data;

            } catch (error) {
              console.error("Error fetching payment URL", error);
              alert("An error occurred while processing your request. Please try again later.");
            }
          } else {
            alert("Please enter an amount between 1 to 100 $");
          }
        } else {
          alert("Please choose a non-decimal value");
        }
      }
    }
  };
  const payInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    }
    else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }


  return (
    <>
      <WebHeader />
      <section id="hero" class="d-flex align-items-center">
        <div class="container">
        <div class="row">
  <div class="d-flex justify-content-center">
    
{isActive.isLogedIn ?"": <button
  href="#about"
  class="btn-get-started"
  data-toggle="modal"
  data-target=".bd-example-modal-lg"
  onClick={() => { checkUser();}}
  id="freeplay"
>
 Social Pick
</button>}
              {isActive.isLogedIn ?
              <button
               data-toggle="modal"
                data-target={isgame || isPaid.gracenumber >= 1 ? ".bd-example-modal-lg" : ".bd-example-modal-lg-1"}
                class="btn-get-started ml-4"
              id="grace2"
              onClick={() => {setGraceGiver(true); }}
            >
              Social Pick
            </button>
                : (
                  ""
                )}
                {isActive.isLogedIn && payment && (

<PaymentModal isGame={isgame} isPaid={isPaid} checkUser={checkUser} />

)}
  </div>
</div>
          <div
            class="modal fade bd-example-modal-lg-1"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div class="form-group">
                      <span> Enter donation amount </span>
                      <input
                        type="number"
                        class="form-control form-control-user mt-2"
                        id=""
                        aria-describedby=""
                        placeholder="Enter Amount"
                        name="amt"
                        onChange={(e) => amountOnChange(e)}
                        value={amt}
                        min="1" max="2"
                        pattern="[0-9]"
                        onKeyDown={(e) => payInput(e)}
                      />
                    </div>
                    {/* 
                    https://preprod.adolphustable.com 
                    http://localhost:8000
                    */}
                    <div class="form-group">
                      {
                        amt !== "" ?
                          <button
                            className="btn btn-primary sysbtn"
                            onClick={payNow}
                          >
                            Pay
                          </button> : null

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade bd-example-modal-lg"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  {/* {isActive.isLogedIn ? (
                    isPaid ? (
                      <p> Free play choose any 1 number </p>
                    ) : (
                      <p> Paid play choose 4 number </p>
                    )
                  ) : (
                    ""
                  )} */}
                 {isActive.isLogedIn && !isgame ? (
  <p> Choose any {isPaid.gracenumber}  number{graceGiver && isPaid.gracenumber > 1 ? 's' : ''} </p>
) : (
  ""
)}
                  {!isActive.isLogedIn ? (
                    <div className="d-flex">
                      <p
                        className="signinmodel-text"
                        onClick={() => setSwitchModel({ signin: true })}
                      >
                        Log In
                      </p>
                      <p
                        className="signinmodel-text ml-3"
                        onClick={() => setSwitchModel({ signup: true })}
                      >
                        Register
                      </p>
                      <p
                        className="signinmodel-text ml-3"
                        onClick={() => setSwitchModel({ forgotpassword: true })}
                      >
                        Forgot Password
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {!isActive.isLogedIn && switchModel.signin ? (
                    <div class="row justify-content-center">
                      <div class="col-lg-6">
                        <div class="p-5">
                          <div class="" style={{ marginTop: "-30px" }}>
                            <h3 className="text-center"> Login </h3>
                            <p className="text-center">
                              If you don't have an account yet, click on the
                              "Register" button and fill out the information to
                              create an account. If you do have an account,
                              click on the "Log In" button to log in to your
                              account to play the game.
                            </p>
                          </div>
                          <div class="text-center"></div>
                          <div class="form-group">
                            <input
                              type="email"
                              class="form-control form-control-user"
                              id="exampleInputEmail"
                              placeholder="Enter Email Address..."
                              name="email"
                              onChange={(e) => formHandler(e)}
                              value={user.email}
                            />
                          </div>
                          <div class="form-group">
                            <input
                              type="password"
                              class="form-control form-control-user"
                              id="exampleInputPassword"
                              placeholder="Password"
                              name="password"
                              onChange={(e) => formHandler(e)}
                              value={user.password}
                            />
                          </div>

                          <button
                            class="btn btn-primary btn-user btn-block sysbtn"
                            onClick={signInHandler}
                          >
                            Login
                          </button>
                          <hr />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
{!isActive.isLogedIn && switchModel.forgotpassword ? (
  <div className="row justify-content-center">
    <div className="col-lg-6">
      <div className="p-5">
        <div className="" style={{ marginTop: "-30px" }}>
          <h3 className="text-center">Forgot Password</h3>
          <p className="text-center">
            Please enter your email to reset your password.
          </p>
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-user"
            id="userEmail"
            placeholder="Enter Email Address..."
            name="email"
            onChange={(e) => forgotformHandler(e)} // Ensure formHandler updates state correctly
            value={user.email}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control form-control-user"
            id="newPasswordInput"
            placeholder="New Password"
            name="newPassword"
            onChange={(e) => forgotformHandler(e)} // Ensure formHandler updates state correctly
            value={fuser.newPassword}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control form-control-user"
            id="confirmNewPasswordInput"
            placeholder="Confirm New Password"
            name="confirmNewPassword"
            onChange={(e) => forgotformHandler(e)} // Ensure formHandler updates state correctly
            value={fuser.confirmNewPassword}
          />
        </div>
        <button
          className="btn btn-primary btn-user btn-block"
          onClick={() => {
            resetPasswordHandler();
          }}
        >
          Reset Password
        </button>
      </div>
    </div>
  </div>
) : null}

{!isActive.isLogedIn && switchModel.changed ? (
  <div className="row justify-content-center">
    <div className="col-lg-6">
      <div className="p-5">
        <div className="text-center" style={{ marginTop: "-30px" }}>
          <h3>Password has been reset successfully!</h3>
          <p>Now you can log in with your new password.</p>
          <button
            className="btn btn-primary btn-user"
            onClick={() => {
              setSwitchModel({ signin: true });
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
) : null}



{!isActive.isLogedIn && switchModel.signup ? (
  <div class="row justify-content-center">
    <div class="col-lg-6">
      <div class="p-5">
        <div class="text-center"></div>
        <div class="" style={{ marginTop: "-30px" }}>
          <h3 className="text-center"> Register </h3>
          <p className="text-center">
            Complete your registration to use the same account to play each day.
          </p>
        </div>
        <div class="pl-5"></div>
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-user"
            id=""
            aria-describedby=""
            placeholder="Enter Username"
            name="username"
            onChange={(e) => formHandler(e)}
            value={user.username}
            disabled={btnStatus === 3 ? true : false}
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control form-control-user"
            id="exampleInputEmail"
            aria-describedby="emailHelp"
            placeholder="Enter Email Address..."
            name="email"
            onChange={(e) => formHandler(e)}
            value={user.email}
            disabled={btnStatus === 3 ? true : false}
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control form-control-user"
            id="exampleInputPassword"
            placeholder="Password"
            name="password"
            onChange={(e) => formHandler(e)}
            value={user.password}
            disabled={btnStatus === 3 ? true : false}
          />
        </div>
        <div class="form-group">
          <select
            name="country"
            onChange={(e) => formCouHandler(e)}
            class="form-control form-control-user"
            value={user.country}
            disabled={btnStatus === 3 ? true : false}
          >
            <option selected>Choose country..</option>
            {country.map((item) => (
              <option value={item.name} key={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div class="form-group">
          <select
            name="state"
            onChange={(e) => formCouHandler(e)}
            class="form-control form-control-user"
            value={user.state}
            disabled={btnStatus === 3 ? true : false}
          >
            <option selected>Choose state..</option>
            {state.map((item) => (
              <option value={item.name} key={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div class="form-group">
          <select
            name="city"
            onChange={(e) => formCouHandler(e)}
            class="form-control form-control-user"
            value={user.city}
            disabled={btnStatus === 3 ? true : false}
          >
            <option selected>Choose city..</option>
            {city.map((item) => (
              <option value={item.name} key={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div class="form-group">
          <input
            type="number"
            class="form-control form-control-user"
            id="number"
            aria-describedby="number"
            placeholder="Phone"
            name="number"
            onChange={(e) => formHandler(e)}
            value={user.number}
            disabled={btnStatus === 3 ? true : false}
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control form-control-user"
            id="exampleInputEmail"
            aria-describedby="emailHelp"
            placeholder="Representative ID"
            name="refferedby"
            onChange={(e) => formHandler(e)}
            value={user.refferedby}
            disabled={btnStatus === 3 ? true : false}
          />
        </div>
        <div class="form-group">
          <button
            class="btn btn-primary btn-user btn-block sysbtn"
            onClick={signUpHandler}
          >
            Register
          </button>
        </div>
        <hr />
      </div>
    </div>
  </div>
) : (
                    ""
                  )}
                  {isActive.isLogedIn && !isgame ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <input
                          type="number"
                          value={addNumber}
                          onChange={setnumberHandler}
                          className="form-control col-md-3 mb-3"
                          min="1"
                          max="1000000"
                        />
                        <button
                          className="btn btn-primary ml-3 sysbtn"
                          style={{ height: "40px" }}
                          onClick={addhandler}
                        >
                          {" "}
                          Add{" "}
                        </button>
                      </div>
                      <table class="table table-bordered table-dark">
                        <tbody className="gametable">
                          {listedNumber.map((item) => {
                            return (
                              <tr>
                                {item.map((_item) => {
                                  return (
                                    <td onClick={() => pickNumber(_item)}>
                                      {_item}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="col-md-12 d-flex justify-content-end">
                        {currentIndex !== 0 ? (
                          <button
                            className="btn btn-primary sysbtn"
                            onClick={prevHandler}
                          >
                            <i class="bi bi-arrow-left-circle-fill"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          className="btn btn-primary ml-2 sysbtn"
                          onClick={nextHandler}
                          disabled={next + 10 > 100}
                        >
                          <i class="bi bi-arrow-right-circle-fill"></i>
                        </button>
                      </div>
                      <div className="col-md-12">
                        {chooseNumber.map((item) => {
                          return (
                            <button
                              type="button"
                              class="btn btn-primary ml-2 sysbtn"
                              onClick={() => deleteChooseNumber(item)}
                            >
                              <span class="badge badge-dark">{item}</span>
                              <span className="ml-2">x</span>
                            </button>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {isActive.isLogedIn && isgame ? (
                    <h3>
You have selected your numbers. If you win, you will be notified via email.
You may also view past winners to check if the number you picked was a winner. You can view previous winners on the homepage. The game is reset every Friday evening and the numbers are displayed on the homepage immediately.
                    </h3>
                  ) : (
                    ""
                  )}
                </div>
                <div class="modal-footer">
                  {chooseNumber?.length>0 &&
                    isActive.isLogedIn &&
                    !isgame ? (
                    <button
                      type="button"
                      class="btn btn-primary sysbtn"
                      onClick={gameNumbersHandler}
                    >
                      Submit
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <footer id="footer">
        <div class="container py-4 justify-content-center">
          <ul className="d-flex list-unstyled justify-content-center ">
            <li className="pl-3">
              <span>
                <Link to="/">Home</Link>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/about">About Social Pick</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/privacy">Privacy Policy</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/termsconditions">Terms & Conditions</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                {" "}
                <a href="/rules">How To Play</a>
              </span>
            </li>

            <li className="pl-3">
              <span>
                <a href="/contact">Contact Us</a>
              </span>
            </li>
            <li className="pl-3">
                    <span>
                      <a href="/agent/login">Agent Login</a>
                    </span>
                  </li>
                  <li className="pl-3">
                    <span>
                      <a href="/agent/signup">Agent Signup</a>
                    </span>
                  </li>
            {/* <li className="pl-3">
              <span>
                <a href="/personalqualification">Qualification</a>
              </span>
            </li> */}
            {isActive.isLogedIn ? (
              <li className="pl-3">
                <span>
                  <a href="/profile">Profile</a>
                </span>
              </li>
            ) : null}
            {isActive.isLogedIn ? (
              <li className="pl-3">
                <a href="#" onClick={signOutHandler}>
                  Log Out
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <SocialIcons />
        <div className="container text-center pb-2">
          <p className="">&copy; Copyright . All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Home;
