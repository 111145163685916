import React, { useState } from 'react';
import axios from 'axios';
import ApiServices from "../../utils/services";
import { config } from "../../utils/config";

const ForgotPassword = () => {
  const [user, setUser] = useState({ email: '', otp: '' });
  const [fuser, setFuser] = useState({ otp: '', newPassword: '', confirmNewPassword: '' });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const formHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const forgotformHandler = (e) => {
    setFuser({ ...fuser, [e.target.name]: e.target.value });
  };

  const requestOtpHandler = async () => {
    try {
      const response = await ApiServices.sendOtp({ email: user.email,   otp: ""})
      if (response.status === 200) {
        setIsOtpSent(true);
        alert('OTP Sent Successfully!');
      } else {
        alert('Internal Server error!');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const resetPasswordHandler = async () => {
    const { newPassword, confirmNewPassword, otp } = fuser;
    const email = user.email;

    if (newPassword !== confirmNewPassword) {
      alert("Passwords don't match.");
      return;
    }

    try {
      const response = await axios.post(`${config.apiEndPoint}/web/reset-password-agent-with-otp`, {
        email,
        newPassword,
        otp,
      });

      if (response.status === 200) {
        alert('Password has been reset successfully.');
        setIsPasswordReset(true);
      } else {
        alert('Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      {!isOtpSent ? (
        <div style={{ textAlign: 'center' }}>
          <h3>Forgot Password</h3>
          <p>Please enter your email to receive a one-time password (OTP) for identity verification.</p>
          <div style={{ marginBottom: '10px' }}>
            <input
              type="email"
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              placeholder="Enter Email Address..."
              name="email"
              onChange={formHandler}
              value={user.email}
            />
          </div>
          <button
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={requestOtpHandler}
          >
            Request OTP
          </button>
        </div>
      ) : !isPasswordReset ? (
        <div style={{ textAlign: 'center' }}>
          <h3>Reset Password</h3>
          <p>Enter the one-time password (OTP) sent to your email, then set your new password.</p>
          <div style={{ marginBottom: '10px' }}>
            <input
              type="text"
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              placeholder="Enter OTP..."
              name="otp"
              onChange={forgotformHandler}
              value={fuser.otp}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <input
              type="password"
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              placeholder="New Password"
              name="newPassword"
              onChange={forgotformHandler}
              value={fuser.newPassword}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <input
              type="password"
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
              placeholder="Confirm New Password"
              name="confirmNewPassword"
              onChange={forgotformHandler}
              value={fuser.confirmNewPassword}
            />
          </div>
          <button
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={resetPasswordHandler}
          >
            Reset Password
          </button>
        </div>
      ) : (
<div style={{ textAlign: 'center' }}>
  <h3>Password Reset Successfully</h3>
  <p>Your password has been reset. You can now log in with your new password.</p>
  <p>
    <a href="/agent/login" style={{ color: '#007bff', textDecoration: 'none' }}>Login</a>
  </p>
</div>
      )}
    </div>
  );
};

export default ForgotPassword;
