import React from 'react';

const Modal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      width: '50%', // Makes the modal wider
      minHeight: '200px', // Sets a minimum height
      backgroundColor: '#f0e68c', // A light yellow background color
      padding: '40px', // More padding for better spacing
      zIndex: 1000,
      border: '2px solid #dda0dd', // Purple border
      borderRadius: '10px', // Larger border radius
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds shadow for depth
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <p style={{ 
        color: '#800080', // Text color purple
        fontSize: '20px', // Larger font size
        textAlign: 'center' // Center the text
      }}>
        {message}
      </p>
      <button 
        onClick={onClose}
        style={{
          marginTop: '20px', // Space above the button
          backgroundColor: '#ff6347', // Tomato red background for the button
          color: 'white', // White text color
          border: 'none', // No border
          borderRadius: '5px', // Rounded corners for the button
          padding: '10px 20px', // Padding inside the button
          cursor: 'pointer', // Cursor indicates clickable
          fontSize: '16px' // Larger font size for the button
        }}>
        Close
      </button>
    </div>
  );
};

export default Modal;
