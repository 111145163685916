import axios from "axios";
import { config } from "./config";
import { data } from "jquery";

class ApiServices { 

  async ledgerChanges(cred) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/agent/chances`,
        method: "POST",
        data: cred,
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async fetchEarnings(cred) {
    try {
      console.log(cred)
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/agent-revenue`,
        method: "POST",
        data: cred,
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async adminLogin(cred) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/login`,
        method: "POST",
        data: cred,
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async agentLogin(cred) {
    console.log("I am here")
    try {

      const { data } = await axios({
        url: `${config.apiEndPoint}/web/agent/login`,
        method: "POST",
        data: cred,
      });
      return data;
    } catch (error) {
      return data;
    }
  }
  async agentlist() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/agentlist`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async updateAgentStatus(cred) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/updateAgentStatus`,
        data: cred,
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  
  async webUserList() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/userlist`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async winnerNumbersUpdate(_st) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/winnernumbers`,
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
        data: _st,
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async HourwinnerNumbersUpdate(_st) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/hourwinnernumbers`,
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
        data: _st,
      });
      return data;
    } catch (error) {
      return false;
    }
  }

  async  getHourWinner(cityName) {
    try {
      // Construct the URL with a query parameter for the city name
      const url = `${config.apiEndPoint}/admin/hourwinnernumber?city=${encodeURIComponent(cityName)}`;
      
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tk')}`, // Ensure the Authorization scheme is correct (e.g., Bearer)
        },
      });
      
      return data; // Return the data received from the server
    } catch (error) {
      console.error('Failed to fetch hour winner:', error);
      return false; // Return false or handle the error as per your application's needs
    }
  }
  async getWinnerNumbers() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/winnernumbers`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async webUserSignUp(st) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/create/user`,
        method: "POST",
        data: st,
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async agentUserSignUp(st) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/create/agent`,
        method: "POST",
        data: st,
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async webUserSignIn(st) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/login`,
        method: "POST",
        data: st,
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async  updateWebUserNumber(email, number) {
    try {
        const response = await axios({
            url: `${config.apiEndPoint}/web/updatenumber`, // Adjust this URL to match your actual API endpoint
            method: "POST",
            data: {
                email, // User's email to identify the record to update
                number // New number to update in the record
            },
            headers: {
                'Content-Type': 'application/json', // Assuming JSON data
                // Add any other headers like authentication tokens if required
            }
        });
        
        // Assuming the API returns a JSON response
        return response.data;
    } catch (error) {
        console.error('Error updating user number:', error);
        return false;
    }
}
  async saveGameNumbers(st) {
    try {
      
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/gamenumbers`,
        method: "POST",
        data: st,
        headers: {
          Authorization: localStorage.getItem("wtk"),
        }
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async isGamePlayed(st) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/isgameplayed`,
        method: "POST",
        data: st,
        headers: {
          Authorization: localStorage.getItem("wtk"),
        }
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async winningNumberList() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/winningnumberlist`,
        method: "GET",
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async getUpdatedNumbers(email) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/getnumbers`,
        method: "POST",
        data: {
          email,
        },
        headers: {
          Authorization: localStorage.getItem("wtk"),
        }
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async contactEmailSend(dataBody) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/contacmessage`,
        method: "POST",
        data: dataBody,
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async sendOtp(dataBody) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/sendotp`,
        method: "POST",
        data: dataBody,
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async verifyOtp(dataBody) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/verifyotp`,
        method: "POST",
        data: dataBody,
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async gamePlayed() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/gameplayed`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async getCityName() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/cityname`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async updateCityName(name, status) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/admin/cityname`,
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("tk"),
        },
        data:{
          name,
          status
        }
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async getUserDetails(email) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/getuser`,
        method: "POST",
        data:{
          email
        },
        headers: {
          Authorization: localStorage.getItem("wtk"),
        }
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async updateUserDetails(et) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/updateuser`,
        method: "POST",
        data: et,
        headers: {
          Authorization: localStorage.getItem("wtk"),
        }
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async getCountry() {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/country`,
        method: "GET"
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async getState(dr) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/states`,
        method: "POST",
        data: dr
      });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getCities(cits) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/city`,
        method: "POST",
        data: cits
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async sentQualificationEmail(dt) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/qualification`,
        method: "POST",
        data: dt
      });
      return data;
    } catch (error) {
      return false;
    }
  }
  async gamePlayedHostory(email) {
    try {
      const { data } = await axios({
        url: `${config.apiEndPoint}/web/gameplayedhistory`,
        method: "POST",
        data: { email }
      });
      return data;
    } catch (error) {
      return false;
    }
  }

}
export default new ApiServices();
