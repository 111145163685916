import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AdminThemeProvider from "../components/ThemeProvider";
import DashBoard from "../pages/admin/dashboard";
import SetWinner from "../pages/admin/setWinner";
import Login from "../pages/admin/login";
import Agentlogin from "../pages/admin/agentlogin";
import Agentsignup from "../pages/admin/agentsignup";
import AdminProtected from "./adminProtected";
import UserList from "../pages/admin/userList";
import WinnerNumber from "../pages/admin/winnernumber";
import Home from "../pages/ui/home";
import Winners from "../pages/ui/winners";
import Privacy from "../pages/ui/privacy";
import About from "../pages/ui/aboutus";
import TermsandConditions from "../pages/ui/TermsandConditions";
import Rules from "../pages/ui/rules";
import Contact from "../pages/ui/Contact";
import GamePlayed from "../pages/admin/gameplayed";
import PersonalQualification from "../pages/ui/personalQualification";
import Profile from "../pages/ui/profile";
import PaymentModal from "../pages/ui/PaymentModal";
import WebProtected from "./userProtected";
import Sidebar from "../pages/admin/Sidebar";
import Agents from "../pages/admin/Agents";
import AgentReports from "../pages/admin/AgentReports";
import ForgotPassword from "../pages/ui/ForgotPassword";

const router = createBrowserRouter([
  // {
  //   path: 'screen',
  //   element: <Screen />,
  //   errorElement: <p>jjj</p>
  // },
  {
    path: "/profile",
    element: (
      <WebProtected>
        <Profile />
      </WebProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/payment-status/:PAYID",
    element: (
      <WebProtected>
        <PaymentModal />
      </WebProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/privacy",
    element: <Privacy /> ,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/about",
    element: <About />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/termsconditions",
    element: <TermsandConditions />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/contact",
    element: <Contact />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/personalqualification",
    element: <PersonalQualification />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/rules",
    element: <Rules />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/",
    element: <Home />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/supervisor/login",
    element: <Login />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/agent/login",
    element: <Agentlogin/>,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/agent/forgotpassword",
    element: <ForgotPassword/>,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/agent/signup",
    element: <Agentsignup/>,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/agent/dashboard",
    element: <Sidebar/>,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
          <DashBoard />
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/setwinner",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
          <SetWinner />
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/userlist",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
          <UserList />
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/agents",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
          <Agents/>
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/agentreports",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
        <AgentReports/>
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/winnernumber",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
          <WinnerNumber />
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/gameplayed",
    element: (
      <AdminProtected>
        <AdminThemeProvider>
          <GamePlayed />
        </AdminThemeProvider>
      </AdminProtected>
    ),
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
  {
    path: "/winners",
    element: <Winners />,
    errorElement: (
      <h2 className="text-white text-center" style={{ margin: "20%" }}>
        {" "}
        Internal Server Error !{" "}
      </h2>
    ),
  },
]);

const RouterProviders = () => {
  return <RouterProvider router={router} />;
};

export default RouterProviders;
