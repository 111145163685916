import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './payment.css';
import HelperMethod from "../../utils/helper";
import { config } from "../../utils/config";

const PaymentModal = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { PAYID } = useParams(); // Assuming the path variable is named payerId
  const [paymentStatus, setPaymentStatus] = useState('loading');

  // Function to fetch payment history
  const fetchPaymentHistory = async () => {

    const isUser = HelperMethod.jwtVerify(localStorage.getItem("wtk"));
    const email = isUser.data.email // Retrieve email from localStorage
    if (!email || !PAYID) {
      console.log('Email or Payer ID is missing');
      setIsVisible(false);
      return;
    }

    try {
      const response = await fetch(`${config.apiEndPoint}/web/find-payment?payerId=${PAYID}&email=${email}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Process the data as needed
      setPaymentStatus(data.data.paymentStatus);
    } catch (error) {
      console.error('Error fetching payment history:', error);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
  }, [PAYID]); // Depend on payerId to re-fetch when it changes

  const closeModal = () => {
    setIsVisible(false); // Close the modal
    window.location.href = '/';
  };

  let message;
  switch (paymentStatus) {
    case 'success':
      message = 'Payment Successful! Now you can click on grace giver to play.';
      break;
    case 'failed':
      message = 'Payment Failed. Please try again.';
      break;
    default:
      message = 'Fetching Payment Status...';
      break;
  }

  return isVisible ? (
    <div className="modal-backdrop">
      <div className="modal-custom">
        <div className="text-center">
          <h3>{message}</h3>
          {paymentStatus !== 'loading' && (
            <button className="btn btn-secondary mt-3" onClick={closeModal}>
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default PaymentModal;
