import { useEffect, useState } from "react";
import ApiServices from "../../utils/services";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
var $ = require('jquery');


const WinnerNumber = () => {
  const [data, setData] = useState([]);
  const [currentPlayed, setCurrentPlayed] = useState({});
  const fetchData = async () => {
    const data = await ApiServices.winningNumberList();
    console.log(data);
    setData(data.data);
    setTimeout(() => {
      $('#dataTable').DataTable();
  }, 1000)
  };
  const _setCurrentPlayed = (data) => {
    console.log(data);
    // let _winNum = [];
    // data.numbers.map((item) => {
    //   item.numbers.map((_item) => {
    //     _winNum = [..._winNum, _item];
    //   });
    // });
    setCurrentPlayed({
      numbers: data.numbers,
      totalplayed: data.totalplayed,
      winningnumber:data.winningnumber
    });
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Winner Number : { data.length }</h6>
            </div>
            <div class="card-body">
              <div class="row">
              <table
                  class="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th scope="col">Sn</th>
                      <th scope="col">Winners</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      const dt = item.createdAt.split("T");
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <button
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                              className="btn btn-primary"
                              onClick={() => _setCurrentPlayed(item)}
                            >
                              view
                            </button>
                          </td>
                          <td>{dt[0]}</td>
                          <td>{dt[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-12">
             
                  <h3 className="font-weight-bold"> Winner Users & Winner Number {JSON.stringify(currentPlayed?.winningnumber)} </h3>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sn</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>City</th>
                        <th scope="col">Played Numbers</th>
                        <th>Paid Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPlayed?.numbers?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.email}</td>
                            <td>{item?.country}</td>
                            <td>{item?.state}</td>
                            <td scope="col">{JSON.stringify(item?.numbers)}</td>
                            <td>  {item?.graceGiver? "$" + item?.numbers.length / 2 : "Free"} </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <h3 className="font-weight-bold">Game Played User  : {currentPlayed?.totalplayed && currentPlayed?.totalplayed.length} </h3>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Sn</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>City</th>
                        <th scope="col">Played Numbers</th>
                        <th>Paid Amount</th>
                       </tr>
                    </thead>
                    <tbody>
                      {currentPlayed?.totalplayed?.map((item, index) => {
                        return (
                          <tr>
                            <td scope="col">{index + 1}</td>
                            <td>{item?.email}</td>
                            <td>{item?.country}</td>
                            <td>{item?.state}</td>
                            <td scope="col">{JSON.stringify(item?.numbers)}</td>
                            <td>  {item?.graceGiver? "$" + item?.numbers.length / 2 : "Free"} </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">footer</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerNumber;
